import React from 'react';
import Layout from '../components/Layout';

const DuesRecurringPage = () => {
    return (
        <Layout>
            <div className="container-fluid p-0" id="page-top">
                <section className="resume-section p-3 p-lg-5 d-flex align-items-center">
                    <div className="w-100">
                        <h1 className="mb-0">Give <span className="text-primary">Today</span></h1>
                        <h2 className="mb-5">Lambda Iota Alumni Association</h2>
                        <div align="center">
                            <p className="mb-5">Please select from the levels below for making a gift.</p>
                        </div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Level</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Recurrence</th>
                                    <th scope="col">Make Payment</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">50th Sustainer Monthly $50.00</th>
                                    <td>$50.00</td>
                                    <td>Monthly</td>
                                    <td>
                                        <a className="btn btn-success" href="/dues-recurring-form?priceId=price_1H5ItEAAFUDq0L5DVg5p8zHL" role="button">Give</a>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">1973 Founder Monthly $19.73</th>
                                    <td>$19.73</td>
                                    <td>Monthly</td>
                                    <td>
                                        <a className="btn btn-success" href="/dues-recurring-form?priceId=price_1H5It8AAFUDq0L5DVysoiUt5" role="button">Give</a>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Member Monthly $10.00</th>
                                    <td>$10.00</td>
                                    <td>Monthly</td>
                                    <td>
                                        <a className="btn btn-success" href="/dues-recurring-form?priceId=price_1H5It2AAFUDq0L5DRtiguQx9" role="button">Give</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
            </div>
        </Layout>
    )
};

export default DuesRecurringPage;
